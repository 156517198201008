/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.2.1/dist/jquery.min.js
 * - /npm/headroom.js@0.9.4/dist/headroom.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/bootstrap@4.0.0-beta/dist/js/bootstrap.min.js
 * - /npm/jquery-validation@1.19.1/dist/jquery.validate.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
